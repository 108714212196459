// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false, 
  url: 'https://api-tlalnepantla-desaur.zurco.tech/api/v1/',
  //url: 'http://localhost:8080/api/v1/',
  API_KEY_DESAUR: 'API_KEY_DESAUR_VE8aAWsWThCiQBb3QiucJcwk0mm57WUVz8hXDH9RTfxmLEaEs',                   
  firebase: {
    apiKey: "AIzaSyDNNVzQOKbxTkW18bZCtS6jldiVab6KPy4",
    authDomain: "desarrollo-urbano-tlalnepantla.firebaseapp.com",
    projectId: "desarrollo-urbano-tlalnepantla",
    storageBucket: "desarrollo-urbano-tlalnepantla.appspot.com",
    messagingSenderId: "3114447571",
    appId: "1:3114447571:web:5332a197e48546fac7da26",
    measurementId: "G-DMW49LDECN"
  },
};